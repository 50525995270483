import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import {
  ThemeProvider, createTheme,
} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter as Router } from 'react-router-dom';

import MessageProvider, { setInitialMessageData } from 'src/containers/MessageProvider';
import muitheme from 'styles/muitheme';
import { CacheProvider } from '@emotion/react';
import { TssCacheProvider } from 'tss-react';
import createCache from '@emotion/cache';

const theme = createTheme(muitheme);
const muiCache = createCache({
  key: 'mui',
  prepend: true,
});

const tssCache = createCache({
  key: 'tss',
});
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

setInitialMessageData(queryClient);

// NOTE: Don't use <StyledEngineProvider injectFirst/> with muiCache or tssCache
// https://docs.tss-react.dev/troubleshoot-migration-to-muiv5-with-tss

export default function AppProvider({ children }) {
  return (
    <Provider store={window.alchemyStore}>
      <CacheProvider value={muiCache}>
        <TssCacheProvider value={tssCache}>
          <ThemeProvider theme={theme}>
            <QueryClientProvider client={queryClient}>
              <CssBaseline />
              <Router basename={window.URL_ROOT}>
                <MessageProvider>
                  {children}
                </MessageProvider>
              </Router>
            </QueryClientProvider>
          </ThemeProvider>
        </TssCacheProvider>
      </CacheProvider>
    </Provider>
  );
}

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
