const allowedUserFields = [
  'username', 'password', 'firstName', 'middleName', 'lastName', 'userID', 'roles', 'locale',
  'courseLocale', 'email', 'position', 'timezone', 'address', 'phoneNumbers', 'barcodes', 'ssn',
  'worksite', 'dateOfHire', 'supervisingGroups', 'supervisingWorksites', 'dateOfBirth',
  'dateOfTermination', 'baseRate', 'groups', 'adminAlerts', 'learnerAlerts', 'learnerAssignedLpAlerts',
  'directReportUsers', 'userReportsTo', 'alerts', 'needsFreshening', 'meta',
];

/**
 * Returns a object with the list of allowedUserFields
 * @param
 * @return {Object} Object with the alowedUserFields
 */
export default function getAllowedUserFields() {
  return allowedUserFields;
}
