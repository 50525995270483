import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { find } from 'lodash';
import { clearError } from 'actions/errorsActions';
import ErrorDialog from 'src/components/ErrorDialog';

/**
  * @module routes/Manager/components/GenericError
  */

/**
  * GenericError stateless function component
  * Renders a generic error dialog for non HTTP 500 errors (and 404 too)
  * @function GenericError
  * @param {GenericErrorProps} GenericErrorProps - The
  * [props]{@link module:routes/Manager/components/GenericError~GenericErrorProps}
  * for the GenericError component.
  * @return {function} GenericError - stateless GenericError component
  */
export default function GenericError() {
  const dispatch = useDispatch();
  const errors = useSelector((state) => state.errors);
  const form = useSelector((state) => find(state.form));
  const location = useLocation();
  const history = useHistory();

  if (!errors || !errors.status) {
    return null;
  }

  const { status } = errors;
  if ((status >= 500 && status <= 600) || status === 404) {
    clearError()(dispatch);
    return null;
  }

  if (status === 401 && location.pathname !== '/login/') {
    history.push({
      pathname: '/logout/',
      query: '?reason=session_expired',
      state: {
        nextPathname: location.search ? location.pathname + location.search : location.pathname,
      },
    });
    return null;
  }

  // try to find redux-form field targets for the errors:
  // if the form has received a SubmissionError for and has fields that will display them,
  // then we won't show the dialog to avoid redundant error reporting
  if (errors.body && errors.body.errors) {
    // CourseLocked error has no form field associated with it, but error notificaiton is
    // handled through snackbar, no generic error needed.
    const filteredErrors = errors.body.errors.filter((error) => error.id !== 'course_locked');
    if (filteredErrors?.length === 0) {
      clearError()(dispatch);
      return null;
    }
    if (form && form.fields && form.submitErrors) {
      const redundantErrors = filteredErrors.filter((error) => {
        const path = error.path ? error.path.replace('/', '') : null;
        return !!path && !!form.fields[path] && !!form.submitErrors[path];
      });
      // if all the errors are redundant, we won't display the dialog
      if (redundantErrors.length === filteredErrors.length) {
        clearError()(dispatch); // dismiss the errors
        return null;
      }
    }
  }

  return (
    <ErrorDialog
      errors={errors}
      onRequestClose={() => {
        clearError()(dispatch);
      }}
    />
  );
}
