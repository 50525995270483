import FetchUtils from 'utils/fetch-utils';

export const userFields = [
  'id', 'last_name', 'middle_name', 'first_name', 'email', 'archived', 'course_locale',
  'address[address1,address2,city,state,zip,country]', 'locale', 'must_change_password',
  'groups[id,name,type]', 'worksite[id,name]', 'pa_code[id,name]', 'phone_numbers[phone_number]',
  'position', 'roles', 'timezone', 'user_id', 'username', 'validation', 'created_at', 'modified_at',
  'admin_alerts', 'learner_alerts', 'learner_assigned_lp_alerts', 'supervising_groups[id,name,type]',
  'alerts', 'needs_freshening', 'meta',
];

const fields = [
  'token', 'expires_at', 'password_expired', `user[${userFields.toString()}]`,
  'capabilities[accessible_products,accessible_roles, powers,filterable_admin_roles,filterable_course_types]',
];

export function login(username, password) {
  return FetchUtils.post('login/', {
    version: '20160801',
    fields,
    data: {
      username,
      password,
    },
  });
}

export function user() {
  return FetchUtils.get('user/', {
    version: '20160801',
    fields: userFields,
  });
}
