import _ from 'lodash';

import FetchUtils from 'utils/fetch-utils';
import formatPatchBody, { formatAddRemoveOperations } from 'utils/formatPatchBody';
import getAllowedUserFields from 'utils/getAllowedUserFields';
import getValidFields from 'utils/getValidFields';

/**
  * @module services/fetch/patchUser
  */

export const fields = [
  'id', 'last_name', 'middle_name', 'first_name', 'email', 'archived', 'course_locale',
  'address[address1,address2,city,state,zip,country]', 'locale', 'must_change_password',
  'groups[id,name,type]', 'worksite[id,name]', 'pa_code[id,name]', 'phone_numbers[phone_number]',
  'position', 'roles', 'timezone', 'user_id', 'username', 'validation', 'created_at', 'modified_at',
  'admin_alerts', 'learner_alerts', 'learner_assigned_lp_alerts', 'date_of_hire',
  'supervising_groups[id,name,type]', 'alerts', 'needs_freshening', 'meta',
];

const allowedFields = getAllowedUserFields();

function formatValue(newValues, currentProp) {
  const value = newValues[currentProp];
  switch (currentProp) {
    case 'locale':
      if (newValues[currentProp] === 'ask-user') {
        return null;
      }
    // falls through when locale is a real value
    default: return value;
  }
}

/**
  * This function compares before/after data from redux state, determines which API operation
  * is needed, and returns the format required by the patch user endpoint.
  * @function formatUsersPatch
  * @param {Object} origValues - form values prior to changes.
  * @param {Object} newValues - form values after changes have been made to a user.
  * @return {array<Object>} patchOptions - an array of user data objects.
  */
export function formatUsersPatch({ origValues, newValues }) {
  const mainPatch = formatPatchBody({
    origValues,
    newValues,
    ignoredValues: ['supervisingGroups', 'supervisingWorksites', 'groups', 'directReportUsers', 'alerts'],
    expandableFields: ['address'],
    formatValue,
    formatPath(fieldName) {
      return (fieldName === 'address1' || fieldName === 'address2')
        ? fieldName : _.snakeCase(fieldName);
    },
  });

  return [
    ...mainPatch,
    ...formatAddRemoveOperations('direct_report_users', _.map(origValues.directReportUsers, 'id'), _.map(newValues.directReportUsers, 'id'), (id) => ({ id })),
    ...formatAddRemoveOperations('groups', _.map(origValues.groups, 'id'), _.map(newValues.groups, 'id'), (id) => ({ id })),
    ...formatAddRemoveOperations('supervising_groups', _.map(origValues.supervisingGroups, 'id'), _.map(newValues.supervisingGroups, 'id'), (id) => ({ id })),
    ...formatAddRemoveOperations('supervising_groups', _.map(origValues.supervisingWorksites, 'id'), _.map(newValues.supervisingWorksites, 'id'), (id) => ({ id })),
    ...formatAddRemoveOperations('alerts', origValues.alerts, newValues.alerts, (value) => (value)),
  ];
}

/**
  * This function is created to verify any changes made in Form by MultiSelect dialog component
  * will return empty array if no changes are made in Form
  * @function validateFormChanges
  * @param {Object} origValues - Initials Values.
  * @param {Object} newValues - Updated Form Values.
  * @return {array<Object>} patchOptions - an array of user data objects.
  */
export function validateFormChanges(origValues, newValues) {
  return formatUsersPatch({
    origValues: getValidFields(origValues, allowedFields),
    newValues: getValidFields(newValues, allowedFields),
  });
}

/**
  * This function calls FetchUtils.patch with the provided userID and options
  * and returns a promise.
  * @function patchUser
  * @param {number} userID - the user whose records should be updated.
  * @param {Object} options - options to reformat and send to PATCH /users/"userID".
  * @return {Promise} A promise for the user
  */
export function patchUser(userID, { origValues, newValues }) {
  const data = validateFormChanges(origValues, newValues);

  return FetchUtils.patch(`users/${userID}/`, {
    version: '20181106',
    data,
  });
}

/**
 * This function calls FetchUtils.patch for the currently logged in user with options
 * and returns a promise. This patch request must use the an appropriately specified API `version`
 * as provided in the API documentation.
 * @function patchUser
 * @param {Object} options - options to reformat and send to PATCH /users.
 * @return {Promise} - A promise for the updated user.
 */
export function patchCurrentUser({ origValues, newValues, ...options }) {
  let data;
  if (origValues && newValues) {
    data = validateFormChanges(origValues, newValues);
  }

  return FetchUtils.patch('user/', {
    version: '20170623',
    fields,
    data,
    ...options,
  });
}
