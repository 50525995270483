import { useEffect, useMemo } from 'react';
import useCurrentUser from 'src/hooks/useCurrentUser';
import { getAdminRoles } from 'utils/adminRoles';
import Cookies from 'cookies-js';

/**
 * Handles updating the pendo visitor information when the current user changes
 */
export default function usePendo() {
  const clientName = global.employerName;
  const {
    first_name: firstName,
    last_name: lastName,
    email,
    id: userID,
    roles: userRoles = [],
    worksite,
  } = useCurrentUser();

  const adminRoles = useMemo(() => getAdminRoles(userRoles), [userRoles]);
  const { URL_ROOT } = global;
  const { hostname } = window.location;
  // handle tyson.sistemlms.com which has an emtpy URL_ROOT
  const rootSite = (URL_ROOT === '' ? hostname.split('.')[0] : URL_ROOT).replace(/\//g, '');
  const worksiteName = worksite?.name || '';

  useEffect(() => {
    if (!global.pendo || Cookies.get('isImpersonate') === 'true') {
      return;
    }

    global.pendo.updateOptions({
      visitor: {
        id: `${rootSite}_${userID}`,
        email,
        full_name: `${lastName}, ${firstName}`,
        roles: adminRoles,
        worksite: worksiteName,
      },
      account: {
        id: rootSite,
        clientName,
      },
    });
  }, [userID, clientName, lastName, firstName, email, rootSite, adminRoles, worksiteName]);
}
