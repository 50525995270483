import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from 'tss-react/mui';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import AlchemyLogo from 'assets/icons/intertek_alchemy_logo_black.svg';
import useCurrentUser from 'src/hooks/useCurrentUser';

const useStyles = makeStyles()((theme) => ({
  largeFont: {
    fontSize: theme.spacing(9),
    color: theme.palette.text.lightGrey,
    fontWeight: '700',
    marginBottom: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2),
    height: 1,
    backgroundColor: theme.palette.text.lightGrey,
  },
  container: {
    paddingTop: theme.spacing(10),
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  logo: {
    marginBottom: theme.spacing(2),
    height: '100%',
    width: '125px',
  },
}));

export default function NotFound() {
  const { classes } = useStyles();
  const { isLoggedIn } = useCurrentUser();
  return (
    <Container className={classes.container} maxWidth="sm">
      {!isLoggedIn && (
        <img src={AlchemyLogo} alt="Intertek Alchemy Logo" className={classes.logo} />
      )}
      <Typography className={classes.largeFont} align="center" variant="h1">404</Typography>
      <Typography variant="h5" align="center" gutterBottom>
        <FormattedMessage id="errors.page_not_found" />
      </Typography>

      {!isLoggedIn && (
        <Typography align="center" variant="body1">
          <FormattedMessage id="errors.404_alt_message" />
        </Typography>
      )}

      {isLoggedIn && (
        <>
          <Typography align="center" variant="body1">
            <FormattedMessage id="errors.404_message" />
          </Typography>

          <Divider
            variant="fullWidth"
            flexItem
            className={classes.divider}
            sx={{
              opacity: '0.6',
            }}
          />

          <Typography align="center" variant="body2">
            <FormattedMessage
              id="errors.404_support"
              values={{
                /* These components are fine being "unstable" as they don't contain any state */
                /* eslint-disable react/no-unstable-nested-components */
                MailSupport: <a href="mailto:support@alchemysystems.com">support@alchemysystems.com</a>,
                /* eslint-enable react/no-unstable-nested-components */
              }}
            />
          </Typography>
        </>
      )}
    </Container>
  );
}
