import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import getConfig from 'utils/getConfig';
import { getConfigurationIfNeeded } from 'actions/configActions';

/**
 * Allows functional components to request individual config values
 * for a given key regardless of wether they are already in state or
 * need to be retrieved via an api call.
 *
 * @param {string} name - the key/name of the config to obtain
 * @param {string|number|boolean} defaultValue - optional default value
 * @return {string|number|boolean} the config value
 */
export default function useConfig(name, defaultValue = undefined) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getConfigurationIfNeeded(name));
  }, [name, dispatch]);
  return useSelector(({ configs }) => getConfig(configs, name, defaultValue));
}

export function useConfigWithoutFetch(name, defaultValue) {
  return useSelector(({ configs }) => getConfig(configs, name, defaultValue));
}
