import React from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { userFields, user as fetchUser } from 'services/fetch/login';
import handleError from 'services/fetch/utils/handleError';
import { patchCurrentUser } from 'services/fetch/patchUser';
import { useMessages } from 'src/hooks/useMessages';
import processCurrentUser from 'utils/processCurrentUser';

export const CurrentUserContext = React.createContext();

const queryKey = ['currentUser'];

export default function useCurrentUser() {
  const { data: user } = useQuery(queryKey, async () => {
    const { data } = await fetchUser();
    return processCurrentUser(data);
  }, {
    // Freshen the current users data every 10 minutes
    staleTime: 10 * 60 * 1000,
    // Use the user data from the initial html if it exists
    initialData: () => processCurrentUser(global.user || {}),
  });
  return user;
}

export function useMutateCurrentUser({ fields, version = '20160801', ...options } = {}) {
  const queryClient = useQueryClient();
  const { setLocale } = useMessages();
  return useMutation(async ({ origValues, newValues }) => {
    const headers = {};
    if (newValues?.passwordCurrent?.length !== 0 && version >= '20170623') {
      headers['X-Old-Password'] = newValues.passwordCurrent;
    }

    try {
      const { data: user } = await patchCurrentUser({
        version,
        origValues,
        newValues,
        fields: fields || userFields,
        headers,
      });
      setLocale(user.locale);
      queryClient.setQueryData(queryKey, processCurrentUser(user));
      return user;
    } catch (error) {
      return handleError(error);
    }
  }, options);
}
