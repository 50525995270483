// This should only contain message that are used on the login screen.
// All messages should have a message in every available locale.

const availableLocales = ['en-US', 'es-US', 'fr-CA', 'en-GB', 'es-MX', 'pt-BR', 'fr-FR', 'de-DE', 'ru-RU'];

const formats = {
  date: {
    year: { year: 'numeric' },
    noTime: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    },
    full: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
};

const enUs = require('./en-US/login.json');
const esUs = require('./es-US/login.json');
const frCa = require('./fr-CA/login.json');
const enGb = require('./en-GB/login.json');
const esMx = require('./es-MX/login.json');
const ptBr = require('./pt-BR/login.json');
const frFr = require('./fr-FR/login.json');
const deDe = require('./de-DE/login.json');
const ruRu = require('./ru-RU/login.json');

const library = {
  formats,
  availableLocales,
  locales: ['en-US', 'es-US', 'fr-CA', 'en-GB', 'en-UK', 'es-MX', 'pt-BR', 'fr-FR', 'de-DE', 'ru-RU'],
  messages: {
    'en-US': enUs.messages,
    'es-US': esUs.messages,
    'fr-CA': frCa.messages,
    'en-GB': enGb.messages,
    'es-MX': esMx.messages,
    'pt-BR': ptBr.messages,
    'fr-FR': frFr.messages,
    'de-DE': deDe.messages,
    'ru-RU': ruRu.messages,
  },
};

export default library;
