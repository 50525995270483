import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import useLogout from 'src/hooks/useLogout';

export default function Logout() {
  const history = useHistory();
  const location = useLocation();
  const { mutate: logout } = useLogout({
    onSuccess: () => {
      window.location = history.createHref({ pathname: '/login/', search: location.search });
    },
  });

  useEffect(() => {
    logout();
  }, [logout]);

  return null;
}
