import React from 'react';
import { render } from 'react-dom';

import AppProvider from 'src/containers/AppProvider';
import App from 'src/containers/App';

render(
  <AppProvider><App /></AppProvider>,
  document.getElementById('page-div'),
);
