import { useContext, useEffect } from 'react';
import { MessageContext } from 'src/containers/MessageProvider/MessageContext';

export function useMessages(overrideLocale) {
  const context = useContext(MessageContext);
  const { setLocale } = context;
  useEffect(() => {
    if (overrideLocale) {
      setLocale(overrideLocale);
    }
  }, [overrideLocale, setLocale]);
  return context;
}
