import { useCallback, useState } from 'react';
import Cookies from 'cookies-js';

// Sets the path to use the client name like the old ui
Cookies.defaults = {
  path: `${window.URL_ROOT}/`,
};

export default function useCookie(cookieName) {
  const [value, setValue] = useState(() => Cookies.get(cookieName) || null);

  const updateCookie = useCallback(
    (newValue, options) => {
      Cookies.set(cookieName, newValue, options);
      setValue(newValue);
    },
    [cookieName],
  );

  const deleteCookie = useCallback(() => {
    Cookies.expire(cookieName);
    setValue(null);
  }, [cookieName]);

  return [value, updateCookie, deleteCookie];
}
