import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { FormattedMessage } from 'react-intl';

export default function Loading() {
  return (
    <Box alignItems="center" display="flex" flexDirection="column" m={2} className="throbberReact">
      <FormattedMessage id="loading" />
      <CircularProgress size={35.7} />
    </Box>
  );
}
