import { useMutation, useQueryClient } from 'react-query';
import { logout } from 'services/fetch/logout';
import processCurrentUser from 'utils/processCurrentUser';
import useCookie from './useCookie';

export default function useLogout(options) {
  const queryClient = useQueryClient();
  const [, , removeXUserLogin] = useCookie('XUserLogin');
  return useMutation(async (token) => logout(token), {
    ...options,
    onSuccess(...args) {
      removeXUserLogin();
      queryClient.setQueryData(['currentUser'], processCurrentUser({}));
      options?.onSuccess?.(...args);
    },
  });
}
