import { isEqual, snakeCase } from 'lodash';

function defaultFormatValue(newValues, currentProp) {
  return newValues[currentProp];
}

function defaultFormatPath(currentProp) {
  return snakeCase(currentProp);
}

export function formatAddRemoveOperations(path, oldIds, newIds, addValueSpec = (id) => (id)) {
  if (isEqual(oldIds, newIds)) {
    return [];
  }

  const basePath = path ? `/${path}` : '';

  return [
    ...oldIds.filter((id) => !newIds.includes(id)).map((id) => ({ op: 'remove', path: `${basePath}/${id}` })),
    ...newIds.filter((id) => !oldIds.includes(id)).map((id) => ({ op: 'add', path: `/${path}`, value: addValueSpec(id) })),
  ];
}

export function formatAddOperationsForListing(entries) {
  return entries.map((entry) => ({ op: 'add', path: '/', value: entry }));
}

/**
 * This handles formating a valid patch body when given the original values
 * and the values that changed.
 */
export default function formatPatchBody({
  // Object of the original values
  origValues,

  // Object of the new values
  newValues,

  // Optional list of fields to patch even if they did not change
  forcePatch = [],

  // Optional list of field to not patch even if they did change
  ignoredValues = [],

  // Optional function to format the values
  // Will be called with the following parameters:
  // newValues - list of new values
  // currentProp - the name of the field to get the value for
  formatValue = defaultFormatValue,

  // Optional function to format the path
  // Will be called with the following parameters:
  // currentProp - the name of the field to generate the path for
  formatPath = defaultFormatPath,

  // Optional list of fields to traverse
  expandableFields = [],

  // used internally to handle nested paths
  // prefixes the formatted path seprated by a `/`
  pathPrefix = '',
}) {
  const changedProps = Object.keys(newValues).filter((key) => (
    forcePatch.includes(key)
    || (!isEqual(origValues[key], newValues[key]) && !ignoredValues.includes(key))
  ));

  return changedProps.flatMap((currentProp) => {
    if (typeof newValues[currentProp] === 'object'
      && newValues[currentProp] !== null
      && expandableFields.includes(currentProp)
    ) {
      return formatPatchBody({
        origValues: origValues[currentProp],
        newValues: newValues[currentProp],
        formatValue,
        formatPath,
        pathPrefix: `/${formatPath(currentProp)}`,
      });
    }

    return {
      value: formatValue(newValues, currentProp),
      path: `${pathPrefix}/${formatPath(currentProp)}`,
      op: 'replace',
    };
  });
}

/**
 * Handles creating a patch body for a list of objects.
 */
export function formatMassPatchBody({
  // Array of objects of the original values
  origList,

  // Array of object of the new values
  newList,

  // key to use on each object to find the id,
  idKey = 'id',

  ...rest
}) {
  return origList.flatMap((origValues) => {
    const newValues = newList.find((obj) => obj[idKey] === origValues[idKey]) || {};
    return formatPatchBody({
      ...rest,
      newValues,
      origValues,
      pathPrefix: `/${origValues[idKey]}`,
    });
  });
}
