import actionTypes from '../constants/actionTypes';

/**
 * @deprecated - Please use [link] actions/errorsAction/clearErrors
 * This function dispatches a CLEAR_ERROR action.
 * @function clearError
 * @param {Object} options -method options.
 * @return {Object} dispatched action - Returns an dispatched action of type CLEAR_ERROR
 */
export function clearError(options = {}) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CLEAR_ERROR,
      options,
    });
  };
}

/**
 * This function returns a CLEAR_ERROR action.
 * @function clearErrors
 * @param {Object} options -method options.
 * @return {Object} action - Returns an action of type CLEAR_ERROR
 */
export function clearErrors(options = {}) {
  return {
    type: actionTypes.CLEAR_ERROR,
    options,
  };
}
