export default function handleError(exception) {
  if (exception.response) {
    return exception.response.json().then((json) => {
      const error = new Error(exception.message);
      error.body = json;
      // json property is needed to be consistant with the way handleServerException works
      error.json = json;
      error.status = exception.response.status;
      throw error;
    });
  }
  return Promise.reject(exception);
}
