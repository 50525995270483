/**
 * This function takes the fields and allowedFields objects and returns
 * the filtered fieds that can be processed by the API.
 * @function getValidFields
 * @param  {Object} fields        - Object with the fields to filter
 * @param  {Object} allowedFields - Object with allowed fields
 * @return {Object}               - Object with only the valid fields
 */
export default function getValidFields(fields, allowedFields) {
  const filteredFields = Object.entries(fields).filter(([key]) => allowedFields.includes(key));
  return Object.fromEntries(filteredFields);
}
