import { without } from 'lodash';
import flattenData from 'utils/flatten-data';

export default function processCurrentUser(user) {
  const isFacilitator = !!user?.roles?.includes('FACILITATOR');
  const isCoach = !!user?.roles?.includes('COACH');
  return {
    ...user,
    ...(user && Object.entries(user).length ? flattenData(user) : {}),
    isLoggedIn: !!user?.id,
    isFacilitator,
    isCoach,
    isCoachOrFacilitator: !!(isCoach || isFacilitator),
    isEnglish: !!user?.locale?.includes('en_'),
    isAdmin: !!(user?.roles && without(user.roles, 'FACILITATOR', 'STUDENT', 'COACH', 'SISTEM', 'CATEGORY_EDITOR', 'DYNAMIC_GROUP_EDITOR', 'JTT_APP_ADMIN', 'QUALIFIER').length > 0),
    isSysAdmin: !!user?.roles?.includes('SYSADMIN'),
    isSistemClient: !!user?.roles?.includes('SISTEM'),
  };
}
