import actionTypes from 'constants/actionTypes';

/**
 * @module actions/snackbarActions
 */

/**
 * This function creates a SHOW_SNACKBAR actions with the options sent.
 * @function showSnackbar
 * @param {Object} options - the snack bar options
 * @return {Object} Action of type SHOW_SNACKBAR.
 */
export function showSnackbar(options) {
  return {
    type: actionTypes.SHOW_SNACKBAR,
    options,
  };
}

/**
 * This function creates a CLOSE_SNACKBAR actions.
 * @function closeSnackbar
 * @return {Object} Action of type CLOSE_SNACKBAR
 */
export function closeSnackbar() {
  return {
    type: actionTypes.CLOSE_SNACKBAR,
  };
}
