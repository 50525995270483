import { merge } from 'lodash';

export default function getLocaleData(locale) {
  return Promise.all([
    /**
     * We are disabling this rule because of an issue in babel-eslint
     * The error message says 'Cannot read property 'range' of null'
     * Not using tempaltes in the import statment works around this issue
     * https://github.com/babel/babel-eslint/issues/608
     * https://github.com/babel/babel-eslint/issues/741
     * https://github.com/babel/babel-eslint/issues/799
     * https://github.com/babel/babel-eslint/issues/815
     */
    /* eslint-disable prefer-template */
    import('../../src/Service/intl/json/' + locale + '.shared.json'),
    import('intl/' + locale + '/main.json'),
    /* eslint-enable prefer-template */
  ]).then((values) => merge({ locale }, ...values));
}
