import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { merge } from 'lodash';
import useUrlQuery from 'src/hooks/useUrlQuery';
import { IntlProvider } from 'react-intl';
import { useQuery, useQueryClient } from 'react-query';
import getLocaleData from 'services/getLocaleData';
import useCurrentUser from 'src/hooks/useCurrentUser';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment-timezone';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import loginLibrary from 'intl/login';
import flattenMessages from 'utils/flatten-messages';

import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/pt';
import 'moment/locale/de';
import { MessageContext } from './MessageContext';

// Pull the message data from the inital html render and load it into
// react-query's cache. This is run in the AppProvider file as it creates the
// queryClient and it can be run before the app is booted.
export function setInitialMessageData(queryClient) {
  if (window.messagesLocale && window.messages && window.sharedMessages) {
    queryClient.setQueryData(['localeData', window.messagesLocale], {
      locale: window.messagesLocale,
      // deep merge is needed to prevent overwriting of nested messages
      messages: flattenMessages(merge(
        {},
        loginLibrary.messages[window.messagesLocale],
        window.messages.messages,
        window.sharedMessages.messages,
      )),
    });
  }
}

async function fetchLocaleData(locale) {
  const data = await getLocaleData(locale);
  return {
    ...data,
    // deep merge is needed to prevent overwriting of nested messages
    messages: flattenMessages(merge({}, loginLibrary.messages[locale], data.messages)),
  };
}

function decideLocale(queryLocale, userLocale) {
  const { availableLocales } = loginLibrary;
  if (queryLocale && availableLocales.includes(queryLocale.replace('_', '-'))) {
    return queryLocale.replace('_', '-');
  }
  if (userLocale && availableLocales.includes(userLocale.replace('_', '-'))) {
    return userLocale.replace('_', '-');
  }
  if (window.messagesLocale) {
    return window.messagesLocale;
  }
  return 'en-US';
}

export default function MessageProvider({ children }) {
  const query = useUrlQuery();
  const queryClient = useQueryClient();
  const { timezone = null, locale: userLocale } = useCurrentUser();
  const [locale, setLocale] = useState(() => decideLocale(query.locale, userLocale));

  const { data: localeData } = useQuery(['localeData', locale], async () => fetchLocaleData(locale), {
    enabled: !!locale,
    staleTime: Infinity, // no need to refetch locale data once we have it.
  });

  const value = useMemo(() => ({
    locale: localeData?.locale,
    setLocale: async (newLocale) => {
      const cleanLocale = newLocale.replace('_', '-');
      if (cleanLocale !== locale && loginLibrary.locales.includes(cleanLocale)) {
        // prefetching so that we have the data before we switch locales
        await queryClient.prefetchQuery(['localeData', cleanLocale], async () => fetchLocaleData(cleanLocale));
        setLocale(cleanLocale);
      }
    },
  }), [locale, localeData?.locale, queryClient]);

  // if timezone isn't defined, moment users the user's browser/computer timezone.
  moment.tz.setDefault(timezone);

  return (
    <MessageContext.Provider value={value}>
      <IntlProvider
        locale={localeData?.locale || 'en-US'}
        messages={localeData?.messages}
        formats={loginLibrary?.formats}
        timeZone={timezone}
      >
        <LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={moment} adapterLocale={localeData?.locale || 'en-US'}>
          {localeData?.messages ? children : null}
        </LocalizationProvider>
      </IntlProvider>
    </MessageContext.Provider>
  );
}

MessageProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
